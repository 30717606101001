<template>
  <v-card
    tile
    light
    max-height="450"
    height="100%"
    transition="scale-transition"
    width="100%"
    max-width="450"
    class="d-flex flex-column"
  >
    <v-toolbar color="secondary" dark max-height="64" style="z-index: 9;">
      <v-toolbar-title>Common Sense Collection Dashboard</v-toolbar-title>
    </v-toolbar>
    <v-row no-gutters>
      <v-col cols="6" align-self="center">
        <sales />
      </v-col>
      <v-col cols="6" align-self="center">
        <categories />
      </v-col>
    </v-row>
    <v-footer color="secondary" dark height="64">
      <v-toolbar-title>Data Proven Results</v-toolbar-title>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: "adv",
  components: {
    Categories: () => import("@/components/adv/categories"),
    Sales: () => import("@/components/adv/sales")
  }
};
</script>

<style scoped></style>
